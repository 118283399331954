import classNames from "classnames";
import React from "react";

export enum UnderlinePositionEnum {
  left = "",
  center = "mx-auto",
}

export enum UnderlineSizeEnum {
  default = "w-[114px]",
  small = "w-[57px]",
}
interface UnderlineProps {
  position: UnderlinePositionEnum;
  color?: string;
  marginTop?: "mt-4" | "mt-2";
  size?: "small";
}

const Underline: React.FC<UnderlineProps> = (props) => {
  const color = props.color ? "border-" + props.color : "border-blueAccent";

  const size =
    props.size === "small"
      ? UnderlineSizeEnum.small
      : UnderlineSizeEnum.default;

  const marginTop = props.marginTop ? props.marginTop : "mt-4";

  const style = classNames(
    "border-t-2",
    size,
    props.position,
    color,
    marginTop
  );

  return <div className={style}></div>;
};

export default Underline;
