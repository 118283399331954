import classNames from "classnames";
import React, { ReactNode } from "react";

interface Heading2Props {
  children: ReactNode;
  className?: string;
  position?: "text-center" | "text-left";
}

const Heading2: React.FC<Heading2Props> = (props) => {
  const position = props.position ? props.position : "text-center";

  const className = classNames(
    "text-4xl font-extrabold font-roboto-condensed leading-[42px] desktop:text-5xl desktop:leading-[56px] uppercase",
    props.className,
    position
  );
  return <h2 className={className}>{props.children}</h2>;
};

export default Heading2;
