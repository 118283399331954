/* eslint-disable @next/next/no-img-element */
import React from "react";
import ContactDetail from "./ContactDetail";
import PhoneIcon from "../../../public/img/phone-black.png";
import EmailIcon from "../../../public/img/email.png";
import LocationIcon from "../../../public/img/location.png";
import FacebookIcon from "../../../public/img/facebook.png";
import InstagramIcon from "../../../public/img/instagram.png";
import YoutubeIcon from "../../../public/img/youtube.png";

interface ContactDetailsProps {
  className: string;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ className }) => {
  return (
    <div className={className}>
      <div>
        <ContactDetail href="tel:+38765419338" icon={PhoneIcon}>
          +387 65 419 338
        </ContactDetail>
        <ContactDetail
          className="mt-4"
          href="mailto:tkmaksimprnjavor@gmail.com"
          icon={EmailIcon}
        >
          tkmaksimprnjavor@gmail.com
        </ContactDetail>
        <ContactDetail
          className="mt-4"
          href={
            "geo:44.880906,17.6710207?q=44.880906,17.6710207(Teniski klub Maksim)"
          }
          icon={LocationIcon}
        >
          <div className="font-bold">Teniski klub “Maksim”</div>
          <div className="mt-3">Ulica Vlade Vinčića</div>
          <div className="mt-3">Prnjavor 78430</div>
        </ContactDetail>
      </div>
      <div className="mt-16 md:mt-12">
        <ContactDetail
          href="https://www.facebook.com/tenismaksim/"
          icon={FacebookIcon}
          newTab
        >
          @tenismaksim
        </ContactDetail>
        <ContactDetail
          className="mt-4"
          href="https://www.instagram.com/tk_maksim_prnjavor/"
          icon={InstagramIcon}
          newTab
        >
          @tk_maksim_prnjavor
        </ContactDetail>
        <ContactDetail
          className="mt-4"
          href="https://www.youtube.com/channel/UCAtb67VNHIdlpwWWfH-cVeQ"
          icon={YoutubeIcon}
          newTab
        >
          /tenismaksim
        </ContactDetail>
      </div>
    </div>
  );
};

export default ContactDetails;
