import React from "react";
import classnames from "classnames";

interface ContainerProps {
  backgroundColor?: "primary" | "secondary";
  className?: string;
  children: React.ReactNode;
  padding?: ContainerPaddings;
}

export enum ContainerPaddings {
  NoPadding,
  Standard,
  TopHalf,
  BottomHalf,
  OnlyTop,
}

const paddingValues = {
  [ContainerPaddings.NoPadding]: ["pt-0, pb-0"],
  [ContainerPaddings.Standard]: ["pt-20 pb-20 md:pt-22 md:pb-24"],
  [ContainerPaddings.TopHalf]: ["pt-10 pb-20 md:pt-12 md:pb-24"],
  [ContainerPaddings.BottomHalf]: ["pt-20 pb-10 md:pt-22 md:pb-12"],
  [ContainerPaddings.OnlyTop]: ["pt-20 md:pt-22"],
};

const Container: React.FC<ContainerProps> = ({
  className,
  padding,
  ...props
}) => {
  const selectedPadding =
    padding != undefined && padding != null
      ? paddingValues[padding]
      : paddingValues[ContainerPaddings.Standard];

  return (
    <div
      className={classnames({
        "bg-greyBg": props.backgroundColor == "secondary",
      })}
    >
      <section
        className={classnames(
          selectedPadding,
          "px-4 md:px-8 max-w-container mx-auto",
          className
        )}
      >
        {props.children}
      </section>
    </div>
  );
};

export default Container;
