/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/Button";
import { useOnScreen } from "../../common/hooks/useOnScreen";

interface MapProps {
  iframe: string;
}

const Map: React.FC<MapProps> = (props) => {
  const ref: any = useRef<HTMLDivElement>();
  const onScreen = useOnScreen<HTMLDivElement>(ref, "0px");
  const [mapVisible, setMapVisible] = useState(false);
  const [mapMounted, setMapMounted] = useState(false);

  useEffect(() => {
    if (onScreen && !mapMounted) {
      setMapMounted(true);
    }
  }, [onScreen, mapMounted]);

  return (
    <div
      className="w-full max-w-container h-[65vw] md:h-[490px] relative"
      ref={ref}
    >
      {mapMounted && (
        <iframe
          src={props.iframe}
          width="100%"
          height="100%"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      )}
      {!mapVisible && (
        <div
          className="absolute top-0 left-0 flex items-center justify-center w-full h-full cursor-pointer backdrop-blur-[2px]"
          onClick={() => {
            setMapVisible(true);
          }}
        >
          <Button
            onClick={() => {
              setMapVisible(true);
            }}
            className="z-20"
          >
            Mapa
          </Button>
        </div>
      )}
    </div>
  );
};

export default Map;
