import React from "react";
import Heading2 from "../../common/text/Heading2";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import { MapSectionProps } from "./MapSection";
import Map from "./Map";

import ContactDetails from "./ContactDetails";

interface MapPhoneProps {
  data: MapSectionProps;
}

const MapPhone: React.FC<MapPhoneProps> = (props) => {
  return (
    <div className="flex flex-col w-full tablet:mx-[10vw]">
      <div className="w-full rounded-2xl bg-greyBg py-16 px-6">
        <Heading2>Kontakt</Heading2>
        <Underline position={UnderlinePositionEnum.center} />

        {/*<div className="flex flex-col tablet:w-[357px] mx-auto mb-[67px]  ml-[10vw]">
          <SymbolDataPair data={props.data.phone} icon={BsTelephone} />
          <SymbolDataPair data={props.data.mail} icon={AiOutlineMail} />
          <SymbolDataPair data={props.data.location} icon={GrLocation} />
          <SymbolDataPair data={props.data.facebook} icon={AiFillFacebook} />
          <SymbolDataPair data={props.data.instagram} icon={AiFillInstagram} />
          <SymbolDataPair data={props.data.youtube} icon={AiFillYoutube} />
  </div>*/}
        <ContactDetails className="mt-8"></ContactDetails>
      </div>
      <div className="mt-20">
        <Map iframe={props.data.iframe} />
      </div>
    </div>
  );
};

export default MapPhone;
