import React from "react";
import { useIsPhone } from "../../common/hooks/useMediaQuery";
import Container, { ContainerPaddings } from "../../layout/Container";
import MapDesktop from "./MapDesktop";
import MapPhone from "./MapPhone";

const props = {
  __component: "page-components.map-section",
  id: 2,
  iframe:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1676.740470269204!2d17.669544558255357!3d44.880943095320504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475dde5dbc1bc9a9%3A0x8611e2be4b021ac4!2sTeniski%20klub%20%22Maksim%22!5e1!3m2!1ssr!2sba!4v1651694606429!5m2!1ssr!2sba",
  title: "Kontakt",
  phone: "+387 65 419 338",
  mail: "tkmaksimprnjavor@gmail.com",
  location: "Teniski klub “Maksim” Ulica Vlade Vinčića Prnjavor 78430",
  facebook: "@tenismaksim",
  instagram: "@tk_maksim_prnjavor",
  youtube: "/tenismaksim",
};

export interface MapSectionProps {
  __component: string;
  iframe: string;
  title: string;
  phone: string;
  mail: string;
  location: string;
  facebook: string;
  instagram: string;
  youtube: string;
}

const MapSection: React.FC = () => {
  const isPhone = useIsPhone();

  return (
    <Container
      className="flex justify-center w-full"
      padding={ContainerPaddings.NoPadding}
    >
      {isPhone ? <MapPhone data={props} /> : <MapDesktop data={props} />}
    </Container>
  );
};

export default MapSection;
