import React from "react";
import { MapSectionProps } from "./MapSection";
import Heading2 from "../../common/text/Heading2";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import Map from "./Map";

import ContactDetails from "./ContactDetails";

interface MapDesktopProps {
  data: MapSectionProps;
}

const MapDesktop: React.FC<MapDesktopProps> = (props) => {
  return (
    <div className="flex w-full p-16 max-w-container rounded-2xl bg-greyBg">
      <div className="w-3/5">
        <Map iframe={props.data.iframe} />
      </div>
      <div className="ml-[88px] ">
        <Heading2 position="text-left">{props.data.title}</Heading2>
        <Underline position={UnderlinePositionEnum.left} />
        <ContactDetails className="mt-8"></ContactDetails>
      </div>
    </div>
  );
};

export default MapDesktop;
